import React from 'react'
import $ from 'jquery'
import { useLocation } from "react-router-dom";
import { GrClose } from 'react-icons/gr';
import { MdAccountCircle } from "react-icons/md";
import { GoTasklist } from "react-icons/go";
import { MdAddTask } from "react-icons/md";
import { PiUserList } from "react-icons/pi";
import { PiPasswordBold } from "react-icons/pi";
import Addtask from './Addtask';
import Listtask from './Listtask';
import Listuser from './Listuser';
import Dashboardpassword from './Dashboardpassword';
import Sitesetting from './Sitesetting';
import { FaSitemap } from "react-icons/fa";


$(document).ready(function () {
    if ($(window).width() < 600) {       // if width is less than 600px
        $(".nav-link").click(function () {
            $("#mySidenav").css("width", "0px");
        });
    }
    else {
        // $('#v-pills-asset-tab').click(function () {
        //   location.reload();
        // });
    }

    $("#mobile-three-line-collapse").click(function () {
        $("#mySidenav").css("width", "250px");
    });
    $("#mobile-close-collapse").click(function () {
        $("#mySidenav").css("width", "0px");
    });
    $(".dashboard-profile-table-tabs-section-hide").hide();
    $(".details-button-navigation button").click(function () {
        $(".dashboard-profile-section-hide").hide();
        $(".dashboard-profile-table-tabs-section-hide").show();
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $(".button-dashboard-table-back").click(function () {
        $(".dashboard-profile-section-hide").show();
        $(".dashboard-profile-table-tabs-section-hide").hide();
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });



    $("#mobile-close-arrow-right").hide();
    $("#mobile-close-arrow-left").click(function () {
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $("#mobile-close-arrow-right").click(function () {
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });


    if ($('#mySidenav').css('width') === '100px') {
        $('#mySidenav').css('a');
    }
});

const logout = () =>{
    localStorage.clear()
}

function Dashboard() {
    const location = useLocation();
    return (
        <div className='App dashboard-navhide-section min-vh-100'>

            <div className='container-fluid'>
                <div className="row">
                    {/* Sidebar */}
                    <div>
                        <span id="mobile-three-line-collapse">&#9776;</span>
                        <div className="dashboard-sidenav-section" id="mySidenav">
                            <nav
                                id="sidebar"
                                className="col-md-12 col-lg-12 d-md-block sidebar component-navbar"
                            >
                                <div className="d-flex flex-row">
                                    <div className="ms-auto me-3">
                                        <span id="mobile-close-collapse"><GrClose />
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="d-flex flex-row">
    <div className="ms-auto">
      <span id="mobile-close-arrow-left"><IoMdArrowDropleft /></span>
      <span id="mobile-close-arrow-right"><IoMdArrowDropright /></span>
    </div>
  </div> */}
                                <div className="d-flex flex-row pt-2 mb-2">
                                    <div className="mx-auto">
                                        {/* <a className="navbar-brand" href="/"><img src={render12}></img></a> */}
                                    </div>
                                </div>

                                <div className="position-sticky">
                                    <ul className="nav flex-column nav-pills">

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/add-task"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                href="/dashboard/add-task"
                                            >
                                                <MdAddTask className="sidenav-icon-size-css " /> Add Task
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/list-task"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                href="/dashboard/list-task"
                                            >
                                                <GoTasklist className="sidenav-icon-size-css " /> List Task
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/list-user"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                href="/dashboard/list-user"
                                            >
                                                <PiUserList className="sidenav-icon-size-css " /> List User
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/change-password"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                href="/dashboard/change-password"
                                            >
                                                <PiPasswordBold className="sidenav-icon-size-css " /> Change Password
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/site-setting"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                href="/dashboard/site-setting"
                                            >
                                                <FaSitemap className="sidenav-icon-size-css " /> Site Setting
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className='nav-link component-tabs'
                                                href="/"
                                                onClick={logout}
                                            >
                                                <MdAccountCircle className="sidenav-icon-size-css" /> Log Out
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>



                    {/* Main Content */}
                    <main className="col-md-12 ms-sm-auto col-lg-10 px-md-4">

                        {location.pathname === "/dashboard/add-task" && <Addtask />}
                        {location.pathname === "/dashboard/list-task" && <Listtask />}
                        {location.pathname === "/dashboard/list-user" && <Listuser />}
                        {location.pathname === "/dashboard/change-password" && <Dashboardpassword />}
                        {location.pathname === "/dashboard/site-setting" && <Sitesetting />}
                        {/* {location.pathname === "/dashboard/userlist" && <Userlist />}
                        {location.pathname === "/dashboard/buyproduct" && <Buyproduct />}
                        {location.pathname === "/dashboard/sellproduct" && <Sellproduct />}
                        {location.pathname === "/dashboard/uploadproduct" && <Uploadproduct />}
                        {location.pathname === "/dashboard/category" && <Category />} */}
                    </main>

                </div>
            </div>

        </div>
    )
}

export default Dashboard