import React, { useState } from 'react';

import { useForm} from "react-hook-form";
import { makeApiRequest } from "../services/commonAxiosService";
import { toast } from "react-toastify";
import { HiMiniEye } from "react-icons/hi2";
import { RiEyeOffFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
function Dashboardpassword() {
  const [inputType, setInputType] = useState('text');
  const [secondinput, setsecondinput] = useState('text');
  const [thirdinput, setthirdinput] = useState('text');
  const navigate = useNavigate();

  const toggleInputType = () => {
   
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };
  const secondInputType = () => {
   
    setsecondinput((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };
  const thirdInputType = () => {
   
    setthirdinput((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log("data",data);
    if(data.confirmPassword !== data.newPassword){
        toast.error("New password and Confirm password not match");
        return
    }else if(data.currentPass === data.newPassword){
        toast.error("Current password and New password not match");
        return
    }else{
      try {
        let params = {
          url: "/change-password",
          method: "post",
          data,
        };
        let response = await makeApiRequest(params);
        console.log(response,"response")
        if (response.status) {
          toast.success(response.message);
          localStorage.clear();
          navigate('/login')
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error(error.response.data);
      }
    }
  };
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 mt-5">
            <h2>Change Password</h2>
            <div className="custom-task-inside">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="mb-3 row position-relative">
                  <label for="currentPass" class="col-sm-4 col-form-label">
                    Current Password
                  </label>
            
                  <div class="col-sm-8 position-relative">
                    <input
                      type={inputType}
                      class="form-control"
                      id="currentPass"
                      {...register("currentPass", { required: true })}
                    />
                    
                    {errors.currentPass &&
                      errors.currentPass.type === "required" && (
                        <span class="error-msg">This field is required</span>
                      )}

                {inputType === 'password' ? (
             <RiEyeOffFill onClick={toggleInputType} className="position-absolute min-eye-icon" />
           ) : (
          <HiMiniEye onClick={toggleInputType} className="position-absolute min-eye-icon" />
             )}

                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="newPassword" class="col-sm-4 col-form-label">
                    New Password
                  </label>
                  <div class="col-sm-8 position-relative">
                    <input
                      type={secondinput}
                      class="form-control"
                      id="newPassword"
                      {...register("newPassword", {
                        required: true,
                        minLength: 8,
                      })}
                    />
                    {errors.newPassword &&
                      errors.newPassword.type === "required" && (
                        <span class="error-msg">This field is required</span>
                      )}
                    {errors.newPassword &&
                      errors.newPassword.type === "minLength" && (
                        <span class="error-msg">
                          This field required min 8 charcters
                        </span>
                      )}
                         {secondinput === 'password' ? (
             <RiEyeOffFill onClick={secondInputType} className="position-absolute min-eye-icon" />
           ) : (
          <HiMiniEye onClick={secondInputType} className="position-absolute min-eye-icon" />
             )}
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="confirmPassword" class="col-sm-4 col-form-label">
                    Confirm Password
                  </label>
                  <div class="col-sm-8 position-relative">
                    <input
                      type={thirdinput}
                      class="form-control"
                      id="confirmPassword"
                      {...register("confirmPassword", {
                        required: true,
                        minLength: 8,
                      })}
                    />
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "required" && (
                        <span class="error-msg">This field is required</span>
                      )}
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "minLength" && (
                        <span class="error-msg">
                          This field required min 8 charcters
                        </span>
                      )}
                         {thirdinput === 'password' ? (
             <RiEyeOffFill onClick={thirdInputType} className="position-absolute min-eye-icon" />
           ) : (
          <HiMiniEye onClick={thirdInputType} className="position-absolute min-eye-icon" />
             )}
                  </div>
                </div>
                <div className="text-center">
                  <button className="custom-btn"> Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboardpassword;
