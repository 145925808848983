import React, { useState } from 'react';

import frame2 from '../Assets/images/login-img.jpg'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import Profile from '../Assets/images/profile.png'
import {Link, useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form"
import { makeApiRequest } from '../services/commonAxiosService';
import { ToastContainer, toast } from 'react-toastify';

function Login() {
    const navigate = useNavigate();
    const year = new Date().getFullYear();
    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    const onSubmit = async(data) => {
        try {
            let params = {
                url : 'admin-login',
                method : 'post',
                data
            }
            let response = await makeApiRequest(params);
            console.log("response",response);
            if(response.status){
                localStorage.setItem('token',response.token)
                toast.success(response.message,{ theme: "colored" })
                navigate("/dashboard/list-task");
            }else{
                toast.error(response.message,{ theme: "colored" })
            }
        } catch (error) {
            toast.error(error.response.data,{ theme: "colored" })
        }
    }

    return (
        <div className='App'>
            <div className='container-fluid'>
                <div className='custom-sign min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5'>
                                    <div className='custom-inside-sign'>
                                        <div className='text-center'>
                                            <img style={{ width: '50px' }} src={Profile}></img>
                                            <p className='fw-bold'>Login</p>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div class="mb-3">
                                                <label for="email" class="form-label fw-bold">Email</label>
                                                <input type="email" class="form-control custom-input" id="email" aria-describedby="emailHelp" placeholder='Example@email.com'
                                                {...register("email", { required: true,
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "invalid email address"
                                                      }
                                                 })} />
                                                {errors.email && errors.email.type === "required" && (
                                                    <span class="error-msg">This field is required</span>
                                                )}
                                                {errors.email && errors.email.type === "pattern" && (
                                                    <span class="error-msg">{errors.email?.message}</span>
                                                )}
                                            </div>
                                            <div className="mb-3 position-relative">
                                                <label for="password" className="form-label fw-bold">Password</label>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    className="form-control custom-input"
                                                    id="password"
                                                    placeholder='At least 8 characters'
                                                    {...register("password", { required: true })}
                                                />
                                                <span
                                                    onClick={togglePasswordVisibility}
                                                    className="position-absolute"
                                                    style={{ right: '10px', top: '39px', cursor: 'pointer',color:'#000' }}
                                                >
                                                    {showPassword ?<BsFillEyeFill /> :<BsFillEyeSlashFill /> }
                                                    {/*  */}
                                                </span>
                                                {errors.password && errors.password.type === "required" && (
                                                    <span class="error-msg">This field is required</span>
                                                )}
                                            </div>
                                            <div className='text-end'>
                                                <Link to="/forgetpassword"><p className='costom-forgot-color'>Forgot Password?</p></Link>
                                            </div>
                                            <div className='text-center w-100'>
                                                <button type="submit" class="custom-sign-btn w-100">Submit</button>
                                            </div>
                                            {/* <div className='text-center mt-3'>
                                                <p>Don't you have an account? <span className='costom-forgot-color'>Sign up</span></p>
                                            </div> */}
                                            <div className='text-center mt-3'>
                                                <p>@ {year} ALL RIGHTS RESERVED</p>
                                            </div>
                                        </form>
                                        <div>
                                            {/* <Darklight/> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7 animi'>
                                    <div className='custom-sign-right'>
                                        <img src={frame2} class='animated-image' alt='Animated Image'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login