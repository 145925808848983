import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { makeApiRequest } from '../services/commonAxiosService';

function Listuser() {
    const [ data,setData ] = useState([]);
    useEffect(()=>{
        const getUsersData = async () =>{
            try {
                let params = {
                    url : "/list-users",
                    method : "get"
                }
                let response = await makeApiRequest(params);
                console.log("response",response);
                if(response.status){
                    setData(response.data)
                }
            } catch (error) {
                console.log('getUsersData error',error)
            }
        }
        getUsersData()
    },[])
    const formatDate = (dateString) => {
        const dateTime = new Date(dateString);
    
        const formattedDateTime = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        }).format(dateTime);
    
        const [month, day, yearTime] = formattedDateTime.split('/');
        const [year, time] = yearTime.split(',');
    
        const formattedDateTimeWithComma = `${year}-${month}-${day}, ${time}`;
    
        return formattedDateTimeWithComma;
      };
    const columnsone = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Chat Id',
            selector: row => row.chatId,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
            width: "150px",
        },
        {
            name: 'First Name',
            selector: row => row.first_name,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: row => row.last_name,
            sortable: true,
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
        },
        {
            name: 'Balance',
            selector: row => row.balance,
            sortable: true,
        },
        {
            name: 'Parent Id',
            selector: row => row.refID,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            cell: (row) => <div className="date">{formatDate(row.date)}</div>
        },

    ];

    const dataone = data &&
    data.map((row, index) => ({
      sno: index + 1,
      chatId: row.id,
      username: row.username,
      first_name: row.first_name,
      last_name: row.last_name,
      address: row.address,
      balance: row.balance,
      refID : row.refID,
      date : row.createdAt
    }));

    return (
        <div className='App'>
            <div className='custom-list-task'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2>List User</h2>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='custom-inside-list-task'>
                                        <div className='liquidity-table-1 mt-3'>
                                            <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                pagination
                                                highlightOnHover
                                                dense />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Listuser