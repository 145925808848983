import React from 'react'
import frame2 from '../Assets/images/forget-img.jpg'
import Profile from '../Assets/images/profile.png'
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { makeApiRequest } from "../services/commonAxiosService";
import { toast } from "react-toastify";

function Forgetpassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    const onSubmit = async (data) => {
        console.log("data",data);
        try {
            let params = {
                url: "/send-email",
                method: "post",
                data,
              };
              let response = await makeApiRequest(params);
              console.log(response,"response")
              if (response.status) {
                toast.success(response.message);
              } else {
                toast.error(response.message);
              }
        } catch (error) {
            toast.error(error.response.data);
        }
    }
  return (
    <div className='App'>
            <div className='container-fluid'>
                <div className='custom-sign min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5'>
                                    <div className='custom-inside-sign'>
                                        <div className='text-center'>
                                            <img style={{ width: '50px' }} src={Profile}></img>
                                            <p className='fw-bold'>Email Address</p>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div class="mb-3 mt-3">
                                                {/* <label for="exampleInputEmail1" class="form-label fw-bold">Email</label> */}
                                                <input type="email" class="form-control custom-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email' {...register("email", { required: true,
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "invalid email address"
                                                      }
                                                 })} />
                                                {errors.email && errors.email.type === "required" && (
                                                    <span class="error-msg">This field is required</span>
                                                )}
                                                {errors.email && errors.email.type === "pattern" && (
                                                    <span class="error-msg">{errors.email?.message}</span>
                                                )}
                                            </div>
                                            <div className='text-center w-100 mt-4 mb-3'>
                                                <button type="submit" class="custom-sign-btn w-100">Forget password</button>
                                            </div>
                                        </form>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7 animi'>
                                    <div className='custom-sign-right'>
                                        <img src={frame2} class='animated-image' alt='Animated Image'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Forgetpassword