import { Outlet, Navigate } from "react-router-dom";
import { makeApiRequest } from "./commonAxiosService";  // Assuming this returns a Promise
import { useState, useEffect } from "react";
import LoadingPage from "../Seperate/Loading";

const ProtectedRoute = () => {
  const [auth, setAuth] = useState(null);  // auth is initially unknown (null)
  const [loading, setLoading] = useState(true);  // Loading state to manage async call

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const params = {
          url: "validAdmin",
          method: "get"
        };
        const res = await makeApiRequest(params); 
        setAuth(true);
      } catch (error) {
        console.error("Error fetching auth status:", error);
        setAuth(false);
      } finally {
          setLoading(false);
      }
    };

    checkAuth();
  }, []);  

  if(loading == false){
    return auth ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
