import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { makeApiRequest } from "../services/commonAxiosService";
import { toast } from "react-toastify";

function Sitesetting() {
    useEffect(()=>{
        getSiteSetting()
    },[])
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();
  const onSubmit = async (data) => {
    console.log("data",data.taskReward)
    try {
    let payLoad = {
        rewardValue : data.taskReward
    }
    let params = {
        url: "/site-setting",
        method: "post",
        data : payLoad
    }
    let res = await makeApiRequest(params)
    console.log(res,"res")
    toast.success(res);
    } catch (error) {
        toast.error("Something went wrong");
    }
  };
  const getSiteSetting = async() =>{
    try {
        let params = {
            url : "/get-site-setting",
            method : "get"
        }
        let res = await makeApiRequest(params)
        setValue("taskReward", res.data.couponCode_Reward || "");
    } catch (error) {
        console.log("getSiteSetting error",error)
    }
  }
  return (
    <div className="App">
      <div className="custom-task">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Site Setting</h2>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-task-inside">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div class="row g-3 align-items-center">
                        <div class="col-auto">
                          <label for="couponReward" class="col-form-label">
                            coupon task reward
                          </label>
                        </div>
                        <div class="col-auto">
                          <input
                            type="text"
                            id="couponReward"
                            class="form-control input-form"
                            {...register("taskReward", {
                              required: true,
                              minLength: 2,
                              validate: (value) =>
                                /^[0-9]+$/.test(value) ||
                                "Only numeric values are allowed",
                            })}
                          />
                          {errors.taskReward &&
                            errors.taskReward.type === "required" && (
                              <span class="error-msg">
                                This field is required
                              </span>
                            )}
                          {errors.taskReward &&
                            errors.taskReward.type === "minLength" && (
                              <span class="error-msg">
                                This field required min 2 charcters
                              </span>
                            )}
                          {errors.taskReward &&
                            errors.taskReward.type === "validate" && (
                              <span className="error-msg">
                                {errors.taskReward.message}
                              </span>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-4">
                        <button className="custom-btn"> Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sitesetting;
