import React, { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component'
import { makeApiRequest } from '../services/commonAxiosService';
import { FaFileDownload } from "react-icons/fa";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'




function Listtask() {

    const [data, setTableData] = useState([]);
    const getTaskData = async () => {
        try {
            let params = {
                url: "/list-task",
                method: "get"
            }
            let response = await makeApiRequest(params);
            console.log("response", response);
            if (response.status) {
                setTableData(response.data)
            }
        } catch (error) {
            console.log('getTaskData error', error)
        }
    }
    useEffect(() => {
        getTaskData()
    }, [])
    const formatDate = (dateString) => {
        const dateTime = new Date(dateString);

        const formattedDateTime = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
        }).format(dateTime);

        const [month, day, yearTime] = formattedDateTime.split('/');
        const [year, time] = yearTime.split(',');

        const formattedDateTimeWithComma = `${year}-${month}-${day}, ${time}`;

        return formattedDateTimeWithComma;
    };
    const deleteTask = async(id) =>{
        withReactContent(Swal).fire({
            title: "Are you sure?",
            text: "You want to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                    let payLoad = {
                        taskId : id
                    }
                    let params = {
                        url: "/delete-task",
                        method: "post",
                        data : payLoad
                    }
                    await makeApiRequest(params);
                    getTaskData()
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                      });
                } catch (error) {
                    console.log('getTaskData error', error)
                    Swal.fire({
                        title: "Cancelled",
                        icon: "error"
                    });
                }
            }
          });
          return
        
    }
    const columnsone = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            width: "150px",
        },
        {
            name: 'URL',
            selector: row => row.desc,
            sortable: true,
            width:"200px"
        },
        {
            name: 'Reward',
            selector: row => row.reward,
            sortable: true,
            width:"200px"
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            width:"200px"
        },
        {
            name: 'Status',
            selector: row => row.status === 1 ? "Active" : "Inactive" ,
            sortable: true,
            width:"200px"
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            cell: (row) => <div className="date">{formatDate(row.date)}</div>,
            width:"200px"
        },
        {
            name: 'Task Img',
            selector: row => row.download,
            sortable: true,

        },
        {
            name: 'Delete Task',
            selector: row => row.button,
            sortable: true,
            width:"200px"

        }
    ];

    const dataone = data &&
        data.map((row, index) => ({
            sno: index + 1,
            title: row.taskTitle,
            desc: row.taskDesc,
            reward: row.taskReward,
            type: row.taskType,
            status: row.status,
            date: row.createdAt,
            download:
                <div className="custom-download-icon">
                    <img src={row.taskImgUrl}/>
                </div>,
            button:
            <div >
                <button className='custom-dow-btn' onClick={()=>deleteTask(row._id)}>Delete</button>
            </div>,
        }));

    return (
        <div className='App'>
            <div className='custom-list-task'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2>List Task</h2>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='custom-inside-list-task'>
                                        <div className='liquidity-table-1 mt-3'>
                                            <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                pagination
                                                highlightOnHover
                                                dense />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Listtask